<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="vx-col justify-center">
     <h1 style="color:orange">{{$t('CustomizeCustomers')}}</h1>
     <div class="vx-row justify-center mt-5">
      
      <div class="lg:w-1/2"  ><h4 class="w-full m-2" style="color:#0675E9;font-weight: bold;text-align:center"> {{$t('WithdrawalPhone')}}</h4></div>
      <div class="lg:w-1/2"> <vs-input        
                    v-model="Model.CustomerWithdrawalPhone"
                    v-validate="'required'"
                    name="CustomerWithdrawalPhone"
                  /></div>
           
     </div>
     <div class="vx-row justify-center mt-5">
      
      <div class="lg:w-1/2"  ><h4 class="w-full m-2" style="color:#0675E9;font-weight: bold;text-align:center"> {{$t('PhoneForDeposit')}}</h4></div>
      <div class="lg:w-1/2"> <vs-input        
                    v-model="Model.CustomerPhoneForDeposit"
                    v-validate="'required'"
                    name="CustomerPhoneForDeposit"
                  /></div>
           
     </div>
    </div>
   
    <div class="vx-col justify-center">
     <h1 style="color:orange">{{$t('CustomizeDrivers')}}</h1>
     <div class="vx-row justify-center mt-5">
      
      <div class="lg:w-1/2"  ><h4 class="w-full m-2" style="color:#0675E9;font-weight: bold;text-align:center"> {{$t('WithdrawalPhone')}}</h4></div>
      <div class="lg:w-1/2"> <vs-input        
                    v-model="Model.DriverWithdrawalPhone"
                    v-validate="'required'"
                    name="DriverWithdrawalPhone"
                  /></div>
           
     </div>
     <div class="vx-row justify-center mt-5">
      
      <div class="lg:w-1/2"  ><h4 class="w-full m-2" style="color:#0675E9;font-weight: bold;text-align:center"> {{$t('PhoneForDeposit')}}</h4></div>
      <div class="lg:w-1/2"> <vs-input        
                    v-model="Model.DriverPhoneForDeposit"
                    v-validate="'required'"
                    name="DriverPhoneForDeposit"
                  /></div>
           
     </div>
    </div>
    
    <div class="vx-col justify-center">
     <h1 style="color:orange">{{$t('CustomizeFleets')}}</h1>
     <div class="vx-row justify-center mt-5">
      
      <div class="lg:w-1/2"  ><h4 class="w-full m-2" style="color:#0675E9;font-weight: bold;text-align:center"> {{$t('WithdrawalPhone')}}</h4></div>
      <div class="lg:w-1/2"> <vs-input        
                    v-model="Model.FleetWithdrawalPhone"
                    v-validate="'required'"
                    name="FleetWithdrawalPhone"
                  /></div>
           
     </div>
     <div class="vx-row justify-center mt-5">
      
      <div class="lg:w-1/2"  ><h4 class="w-full m-2" style="color:#0675E9;font-weight: bold;text-align:center"> {{$t('PhoneForDeposit')}}</h4></div>
      <div class="lg:w-1/2"> <vs-input        
                    v-model="Model.FleetPhoneForDeposit"
                    v-validate="'required'"
                    name="FleetPhoneForDeposit"
                  /></div>
           
     </div>
    </div>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" >{{$t('Submit')}}</vs-button>
    </div>

  </div>
</template>

<script>
import moduleCustomizeRemittances from "@/store/CustomizeRemittances/moduleCustomizeRemittances.js";

export default {
  components: {
  },
  data() {
    return {
      Model:{},
      selected: [],
      search: {},
      showSearchSidebar: false,
      itemsPerPage: 10,
      isMounted: false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
   
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    CustomizeRemittances() {
      return this.$store.state.CustomizeRemittancesList.CustomizeRemittances;
    }
  },

  methods: {
    submitData() {
      if (this.Model.Id !== null && this.Model.Id >= 0) {
            this.$store.dispatch("CustomizeRemittancesList/updateItem", this.Model).then(()=>{
              this.getData();
              window.showSuccess();
            }).catch(err => {
              console.error(err);
            });
          } else {
            this.$store.dispatch("CustomizeRemittancesList/addItem", this.Model).then(()=>{
              this.getData();
             window.showSuccess();
            }).catch(err => {
              console.error(err);
            });
          }
    },

    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },

    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    deleteData(data) {
      this.$store.dispatch("CustomizeRemittancesList/deleteItem", data).then(()=>{
        this.$store
      .dispatch("CustomizeRemittancesList/fetchAllCustomizeRemittancesList")
      }).catch(() => {});
    },

    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("Areyousureyouwantdeletethisrecord"),
        accept: this.acceptAlert
      });
    },
    toggleSearchSidebar(val) {
      this.showSearchSidebar = val;
    },

   

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "danger",
        title: this.$t("Deletedrecord"),
        text: this.$t("The selected record was successfully deleted")
      });
    },
    getData(){
      this.$vs.loading();

    this.$store
      .dispatch("CustomizeRemittancesList/fetchAllCustomizeRemittancesList")
      .then(res => {
        if(res.data.length>0){
          this.Model = res.data[0];
        }
        
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
        window.showError();
      });
    }
  },

  created() {
    if (!moduleCustomizeRemittances.isRegistered) {
      this.$store.registerModule("CustomizeRemittancesList", moduleCustomizeRemittances);
      moduleCustomizeRemittances.isRegistered = true;
    }
    this.getData();
  },

  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          .product-img {
            height: 70px;
            width: 100px;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
