/*=========================================================================================
  File Name: moduleCustomizeRemittances.js
  Description: CustomizeRemittances Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleCustomizeRemittancesState.js";
import mutations from "./moduleCustomizeRemittancesMutations.js";
import actions from "./moduleCustomizeRemittancesActions.js";
import getters from "./moduleCustomizeRemittancesGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
