/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("CustomizeRemittances/Add", item)
        .then(response => {
          commit("ADD_CustomizeRemittances", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchAllCustomizeRemittancesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/CustomizeRemittances/GetListOfCustomizeRemittances")
        .then(response => {
          if (response.status == 200) {
            commit("SET_CustomizeRemittances", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchCustomizeRemittancesByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/CustomizeRemittances/GetCustomizeRemittances", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_CustomizeRemittances", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CustomizeRemittances/Update", item)
        .then(response => {
          debugger
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  deleteItem(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/CustomizeRemittances/Delete" , item)
        .then(response => {
          debugger
          // commit("UPDATE_CustomizeRemittances", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetCCustomizeRemittancesById(context, CustomizeRemittances) {
    if (CustomizeRemittances.Id == undefined) {
      CustomizeRemittances.Id = "";
    }
   
    return new Promise((resolve, reject) => {
      axios
        .post("/CustomizeRemittances/Get", country)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  

};
