/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_CustomizeRemittances(state, item) {
    state.CustomizeRemittances.unshift(item);
  },

  SET_CustomizeRemittances(state, CustomizeRemittances) {
    state.CustomizeRemittances = CustomizeRemittances;
  },

  SET_ALLCustomizeRemittances(state, allCustomizeRemittances) {
    state.AllCustomizeRemittances = allCustomizeRemittances;
  },

  UPDATE_CustomizeRemittances(state, item) {
    const Index = state.CustomizeRemittances.findIndex(p => p.Id == item.Id);
    Object.assign(state.CustomizeRemittances[Index], item);
  }
  
};
